
.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-dark {
	background: #161C24;
	border-right: 1px solid #343B44;
}

@media (min-width: 769px){
    .hide-on-tabs-and-pc {
      display: none !important;
    }
  }
  
  @media (max-width: 768px){
    .hide-on-small-devices {
      display: none !important;
    }
  }

  .div.dropdown-menu.show{
    width: 85px !important;
  }

  .dropdown-menu[data-popper-placement]:not([data-popper-placement^=top-]) {
    top: 2.5rem !important;
    max-width: 10rem !important;
    min-width: 88px !important;
}

.dark-layout .main-menu-content .navigation-main{
	background: #0d0e14 !important;
}
.dark-layout .main-menu-content .navigation-main .active svg {
    color: #ffffff;
}

.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-dark {
	background: #161C24;
	border-right: 1px solid #343B44;
}

.main-menu .navbar-header{
	height: 5rem;
}

.main-menu .navigation > li.active > a {
	box-shadow: none !important;
}

.dark-layout .modal .modal-header .btn-close {
	background-color: transparent !important;
	box-shadow: none !important;
	margin-top: 5px;
	margin-right: 10px;
}


.modal .modal-header .btn-close {
	background-color: transparent !important;
	box-shadow: none !important;
	margin-top: 5px;
	margin-right: 10px;
}

span.build-version {
	font-size: 11px;
	color: #767675;
}


.metamask-login-btn {
	display: flex;
	border-radius: 50px;
	border: 1px solid #6e6e6e;
	padding: 7px 30px;
	margin-bottom: 30px;
	justify-content: center;
	align-items: center;
}


.metamask-login-btn .logo {
	width: 25px;
	margin-right: 5px;

}

.walletconnect-login-btn {
	display: flex;
	border-radius: 50px;
	border: 1px solid #6e6e6e;
	padding: 10px 30px;
	margin-bottom: 10px;
	justify-content: center;
	align-items: center;
}

.walletconnect-login-btn:hover {
	cursor: pointer;
}

.metamask-login-btn:hover {
	cursor: pointer;
}


.walletconnect-login-btn .logo {
	width: 25px;
	margin-right: 5px;
}

.walletconnect-logo{
	width: 25px;
}

.dark-layout .header-navbar {
	background-color: #12141D !important;
}

.buy-token-widget-container {
	display: flex;
	justify-content: center;
}

.login-model-body.modal-body {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dark-layout .data-info-widget.card-body {
	color: #ffffff;
	background: #2a2c34;
}
.vote-info-widget.card-body {
	padding: 1.5rem 1rem 0rem 1rem;
	cursor: pointer;
}
.dark-layout .card-body {
	color: #ffffff;
	background: #2a2c34;
}

.data-info-widget-title{
	font-size: 20px;
	margin-bottom: 10px;
}
.data-info-row {
	display: flex;
	justify-content: space-between;
	line-height: 50px;
}

.data-info-value {
	font-weight: 700;
	font-size: large;
}
.data-info-type {
	font-weight: 700;
	font-size: large;
}
.pool-token-symbol-main {
	font-size: 12px;
	font-weight: 600;
	color: #f85192;
}
.pool-detail-topics {
	font-size: 12px;
	font-weight: 400;
	color: #c7c6c6;
}

.data-info-row {
	margin-bottom: 5px;
}
// .wallet-address.mobile {
// 	font-weight: 800;
// 	border-bottom: 1px dotted rgb(255, 255, 255);
//   }

.data-info-progress-tracker-title {
	// margin: 10px 0;
	text-align: start;
	font-size: 16px;
	font-weight: 800;
}

@media only screen and (max-width: 375px) {
  .data-info-row  {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1024px) {
	.data-info-progress-tracker  {
	  border-top: 1px dotted rgb(255, 255, 255);
	}
	.user-dividend-data-container {
		margin-bottom: 30px;
	}
	.total-user-rewards {
		background-color: #161c24;
		padding-top: 20px;
		border-radius: 10px;
	}
  }

@media only screen and (min-width: 425px) {
  .data-info-widget.card-body {
    min-height: 340px !important;
	}
}

.user-dividend-data-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.user-divided-data-title {
	font-weight: 800;
	font-size: 14px;
}

.user-divided-data-value {
	font-size: 20px;
	font-weight: 800;
}

.claim-your-token-title {
	text-align: center;
	font-size: 20px;
	font-weight: 800;
}
.claim-your-token-value {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.dark-layout .rdt_TableHeadRow{
	background: #191e29;
	color: #ffffff;
	font-weight: 700;
	min-height: 35px;
}

.dark-layout  .rdt_TableRow{
	background: #2a2c34;
	min-height: 40px;
}

.rdt_TableHeadRow{
	min-height: 35px !important;
}

.rdt_TableRow{
	min-height: 40px !important;
}

.dark-layout .rdt_Pagination{
	background: #2a2c34;
	color: #ffffff;
}

.dark-layout #pagination-first-page{
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout #pagination-first-page:disabled{
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-last-page {
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout #pagination-last-page:disabled {
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-previous-page:disabled{
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-next-page:disabled{
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-previous-page{
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout #pagination-next-page{
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout .dt-tnx-hash a{
	color: #ffffff !important;
}

.dark-layout .dt-from a{
	color: #ffffff !important;
}

.dark-layout .dt-to a{
	color: #ffffff !important;
}

.main-menu.menu-dark .navigation li a > * {
    transition: transform 0.25s ease;
    color: #ffffff;
	font-weight: 600;
}
.dark-layout .main-menu .main-menu-content {
    background: #0d0e14;
}
.dark-layout .main-menu .navbar-header {
    background: #0d0e14 !important;
}
.dark-layout .content.app-content {
    // background: #12141D;
	background-image: repeating-linear-gradient(to right, #12141D 0%,  #31318e 50%,#12141D 100%);
}
.dark-layout .vertical-layout.vertical-menu-modern.menu-expanded .footer {
    background: #12141D;
	// background-image: repeating-linear-gradient(to right, #420b0e 0%,  #12141D 50%,#420b0e 100%);
}
.dark-layout input.form-control:not(:focus), [dir] .dark-layout select.form-select:not(:focus), [dir] .dark-layout textarea.form-control:not(:focus) .dark-layout textarea.form-control:focus{
	background: #2a2c34;
}
.dark-layout .input-group .input-group-text {
    background-color: #2a2c34;
    border-color: #88919d;
}
.dark-layout input.form-control:not(:focus), [dir] .dark-layout select.form-select:not(:focus), [dir] .dark-layout textarea.form-control:not(:focus) {
    border-color: #88919d !important;
}
.dark-layout .voted-link {
	
}
.card-header:first-child {
	border-bottom: #90be21 solid 1px;
    padding: 1px;
}
.dark-layout .vote-info {
	border: solid 2px rgb(170, 170, 170) !important;
}
.poll-create-modal {
	width: 700px;
}
.rewards-cal-price-in-usd {
	font-weight: 700;
	font-size: 12px;
	color: #79a01f;
}

.rewards-cal-main-value {
	font-size: 22px;
	padding-bottom: 5px;
}

@media only screen and (max-width: 425px) {
  .token-input-icon {
    display: none;
	}
}
h2 {
	font-weight: bolder;
	margin-bottom: 0.01rem;
}
.card-body {
    border: 1px solid #31318e;
	border-radius: 10px;
}
.dark-layout .card-body {
	background: black !important;
}
.account-balance {
	color: #b80301 !important;
}

.test-classs {
	font-size: large;
	font-weight:500
}
.main-menu.menu-dark .navigation > li ul li {
    background: #161c24;
}

.dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content {
	background-color: transparent;
}

@media only screen and (max-width: 1024px) {
	p {
		margin-bottom: 0.1rem;
	}
	h2 {
		margin-bottom: 1.2rem;
	}
	.row-divider {
		margin-top: 0px !important;
	}
}
.row-divider {
	margin-top: 20px;
}

.result-row {
	display: flex;
	justify-content: space-between;
}

@media only screen and (min-width: 1024px) {
	.spaces-around {
		padding-inline: 150px;
	}
}
@media only screen and (max-width: 991px) {
	.middle-arrow-section {
		transform: rotate(45deg);
		margin-top: 10px;
		margin-bottom: 10px;
	}
}


.result-container {
	line-height: 40px;
    font-weight: 600;
}
.chain-badge {
	margin-right: 5px;
}
.countdown-description {
	font-style: italic;
	font-size: 10px;
	font-weight: 800;
	color: #909090;
}
.account-balance-card {
	height: 145px;
}
.wallet-connect-badge {
	margin-left: 5px;
}
.wallet-chain {
	font-size: 20px !important;
}

span.user-name.fw-bold {
	margin-bottom: 0px !important;
}
.noUi-target, .noUi-target * {
    background: white;
}

.block-time-info-row {
	display: flex;
	justify-content: space-between;
}
.percentage-box {
	border-radius: 10px;
	border: solid  1px;
	padding: 8px 20px;
	cursor: pointer;
}
@media screen and (max-width: 1024px) {
	.percentage-box-col {
		margin-top: 10px;
	}
	.percentage-box {
		padding: 8px 15px;
	}
	.detail-contract-header {
		font-size: 15px !important;
	}
	.contract-detail-badge {
		margin-left: 50% !important;
	} 
	.stake-card-header {
		font-size: 13px !important;
	}
	.token-percentage-button {
		margin-top: 10px !important;
		padding: 8px 10px !important;
		font-size: 12px !important;
	}
}
.collapse-area {
	border: solid  1px;
	padding: 15px;
	cursor: pointer;
	
}

.contract-metadata-container {
	margin-top: 10px;
	font-size: 12px;
}

.metadata-row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	font-size: 13px;
}

.middle-arrow-section {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	height: auto;
}

.migrator-executor-data-title {
	font-weight: 700;
	font-size: 15px;
	text-align: center;
	margin-bottom: 10px;
}

.migrator-pool-data-row {
	font-size: 13px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	// letter-spacing: 1px;
}

.token-pool-amount-input {
	margin-bottom: 10px;
}

.contract-action-button-section {
	display: flex;
	align-content: center;
	justify-content: center;
}

.approve-button {
	margin-right: 10px;
}
.select__control {
    border-radius: 50px;
}
.contract-address {
	font-size: 20px !important;
	font-weight: 500 !important;
	cursor: pointer;
}
.pool-token-name {
	font-size: 13px !important;
	font-weight: 600 !important;
}
.pool-token-address {
	font-size: 12px !important;
	font-weight: 600 !important;
	cursor: pointer;
}
.contract-symbol{
	color: #f85192;
}
a {
    text-decoration: none;
}
.detail-contract-header {
	color: #f85192;
	margin-left: 10px;
	font-size: 20px;
	font-weight: 600;
	margin-top: 7px;
}
.contract-detail-badge {
	align-items: flex-end;
	margin-left: 170%;
}
.detail-name {
	font-size: 15px !important;
	font-weight: 400 !important;
}
.contract-symbol-detail{
	color: #f85192;
	font-size: 15px !important;
	font-weight: 400 !important;
}
.token-percentage-button {
	border: solid 1px #f85192;
	padding: 8px 12px;
	border-radius: 10px;
	cursor: pointer;
	font-weight: bolder;
}
.dark-layout .v-contract-details {
	background: #1f1e1e;
	padding: 8px 15px;
	border-radius: 10px;
}

.v-contract-details {
	border: solid 1px  #1f1e1e;
	padding: 10px 15px;
	border-radius: 10px;
}

h2.pool-amount-value {
	font-size: 20px;
}

.consolidation-ratio-value{
	font-size: 20px;
}
.pool-detail-header-icons {
	margin-top: 10px;
	margin-left: 20%;
	color: white !important;
}
.pool-detail-header-icons-2 {
	margin-top: 10px;
	margin-left: 10px;
	cursor: pointer;
}
.contract-copy-icon {
	margin-left: 10px;
	color: white !important;
	cursor: pointer;
}
.consolidation-ratio-changing-card {
	background: tomato !important;
	border-radius: 10px;
	border: solid 1px white;
	padding: 20px;
}
.consolidation-ratio-header {
	// margin-left: 20px;
}
.migration-admin-zone-title {
	color: #fc4648;
}

.bs-stepper-content{
	padding: 20px !important;
}

.create-pool-details-divider {
	border: #46466a solid 1px !important;
	padding: 20px;
	border-radius: 10px;
	background-color: #0d0e14 !important;
	min-height: 290px !important;
}
.migrator-ratio-label {
	margin-right: 20px !important;
}
.migrator-pool-label,
.migrator-pool-value {
	font-size: 14px;
	font-weight: 500;
}

.contract-metadata-container-outer{
	background-color: #0d0e14 !important;
	margin: 10px 0px;
	border-radius: 10px;
	padding: 10px;
	border: 1px solid #46466a;
}
.contract-details-divider-final {
	border: #5f5f5f solid 1px !important;
	padding: 20px;
	border-radius: 5px;
	background-color: #0d0e14 !important;
}